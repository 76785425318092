<template>
  <v-card>
    <v-card-title>
      <span class="text-h6">
        {{ item.code }} -
        {{
          (item.type && config.TYPE_TEXT && config.TYPE_TEXT[item.type]) || ""
        }}
      </span>
    </v-card-title>
    <v-card-text>
      <div class="error--text">{{ item.note }}</div>
    </v-card-text>
    <v-card-text>
      <input-number
        :label="$t('labels.overtime_money')"
        dense
        outlined
        hide-details
        class="c-input-small"
        v-model.number="money"
      />
    </v-card-text>

    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="red darken-1" text @click="closeDialog">
        {{ $t("labels.cancel") }}
      </v-btn>
      <v-btn
        color="green darken-1"
        text
        @click="confirmOvertime"
        :disabled="!money"
      >
        {{ $t("labels.confirm") }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { MONEY_OPTIONS } from "@/libs/const";
import { httpClient } from "@/libs/http";

export default {
  name: "BeforeConfirm",
  components: {},
  props: {
    item: {
      type: Object,
      default: () => {},
    },
    config: {
      type: Object,
      default: () => {},
    },
  },
  data: () => ({
    isLoading: false,
    detail: {},
    money: "",
    moneyOptions: { ...MONEY_OPTIONS },
  }),
  methods: {
    closeDialog() {
      this.$emit("closeDialog");
    },
    async confirmOvertime() {
      if (this.isLoading) {
        this.$vToastify.warning(this.$t("messages.loading"));
        return false;
      }
      this.isLoading = true;

      try {
        await httpClient.post("/internal-request/v1/change-status", {
          id_internal_request: this.item.id,
          status: this.config.STATUS.DONE,
          money: this.money,
        });
        this.isLoading = false;
        this.$emit("refreshData", true);
        this.closeDialog();
      } catch (e) {
        const errMsg =
          (e.response &&
            e.response.data &&
            e.response.data.error &&
            e.response.data.error.message) ||
          null;
        this.isLoading = false;
        alert(errMsg);
      }
    },
  },
};
</script>

<style scoped></style>
